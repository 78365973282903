var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ea0cb7e4fc098c025dfbc7e646fbc609ee1fc4c6"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || "development";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://3ae7feb1bb8548c99a1baf3055236793@o990350.ingest.sentry.io/5997989",
  environment: ENVIRONMENT,
  release: "2dub-web@2.5.1",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: ENVIRONMENT === "production" ? 0.02 : 0.0,
  sampleRate: ENVIRONMENT === "production" ? 1 : 0.0,
  ignoreUrls: ["http://localhost"],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
